import moment from 'moment';

export const infuraApiKey = '3e369be9e69f4b3984930a8f8f302834';
export const loadingSpinner = `
    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    <span class="sr-only">Loading...</span>
`;

export function formatTimestamp(timestamp) {
    const tooltip = new Date(timestamp * 1000).toLocaleDateString("en-US", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    }).replace(' at ', '<br />');

    return `<span data-bs-toggle="tooltip" data-bs-html="true" data-bs-title="${tooltip}" data-bs-placement="top">${moment.unix(timestamp).fromNow()}</span>`;
}

export function copyAddress(e) {
    const addressControl = e.currentTarget;

    navigator.clipboard.writeText(addressControl.dataset.bsOriginalTitle);

    addressControl.style.transition = '';
    addressControl.style.setProperty('color', 'green', 'important');
    addressControl.style.opacity = '0.75';
    setTimeout(function() {
        addressControl.style.transition = 'color 1s, opacity 1s';
        addressControl.style.removeProperty('color');
        addressControl.style.opacity = '1';
    }, 50);
    setTimeout(function() {
        addressControl.style.transition = '';
    }, 600);
}

export function refreshTooltips() {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-bs-toggle="tooltip"]').tooltip();
    $('.pretty-address').click(copyAddress);
}

export default { infuraApiKey, loadingSpinner, formatTimestamp, copyAddress, refreshTooltips };
